@use '@angular/material' as mat;
@use 'styles/m3-theme';
@use 'styles/theme';

// stylelint-disable-next-line
@include mat.core();

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-style: normal;
  src:
    url('/assets/fonts/ibmplexsans-regular.woff2') format('woff2'),
    url('/assets/fonts/ibmplexsans-regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-style: normal;
  src:
    url('/assets/fonts/ibmplexsans-medium.woff2') format('woff2'),
    url('/assets/fonts/ibmplexsans-medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-style: normal;
  src:
    url('/assets/fonts/ibmplexsans-semibold.woff2') format('woff2'),
    url('/assets/fonts/ibmplexsans-semibold.woff') format('woff');
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font:
    400 14px 'IBM Plex Sans',
    sans-serif;
}

html {
  // Apply the light theme by default
  @include mat.all-component-themes(m3-theme.$light-theme);

  // Adjust material button border radius (used in Admin)
  --mdc-filled-button-container-shape: 4px;
  --mdc-protected-button-container-shape: 4px;
  --mdc-stroked-button-container-shape: 4px;
}

.dark-mode {
  @include mat.all-component-colors(m3-theme.$dark-theme);
}

// to avoid white rectangle showing on mobiles having screen width < 335px
// html, body {
//   overflow-x: hidden !important;
// }

// stylelint-disable-next-line
a {
  color: unset;
}

/* stylelint-disable */
input,
button,
textarea {
  font-family: 'IBM Plex Sans', sans-serif;
}
/* stylelint-enable */

body,
html {
  color: theme.$bp-color-default;
  height: 100%;
}

// stylelint-disable-next-line
strong {
  font-weight: 700;
}
