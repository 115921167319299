@use 'styles/platform/platform-breakpoints-mixins';

::ng-deep .grecaptcha-badge {
  visibility: hidden;
}

.mat-dialog-zero-padding {
  max-width: 95vw !important;

  @include platform-breakpoints-mixins.bp-mobile-view {
    margin-right: -20px;
  }
}

.mat-mdc-dialog-surface {
  box-shadow: none !important;
  position: relative !important;
  background-color: transparent !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  // stylelint-disable-next-line
  overflow-y: visible !important;
  -ms-overflow-style: none; /* hides vertical scroll bar in IE and Edge */
  // stylelint-disable-next-line
  scrollbar-width: none; /* hides vertical scroll bar in Firefox */
  border-radius: 0 !important;

  &::-webkit-scrollbar {
    display: none !important; /* hides vertical scroll bar in Chrome */
  }

  @include platform-breakpoints-mixins.bp-mobile-view {
    margin: 0;
    border-radius: 0 !important;
  }
}
