@use 'styles/theme';

.bp-card {
  @include theme.bp-elevated;
  padding: 24px;
}

.bp-card-new {
  border-radius: 4px;

  @include theme.bp-elevated-new;
}
