@use 'styles/theme';

.bp-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-shrink: 0;
}

.bp-heading {
  font-size: 24px !important;
  font-weight: 500 !important;
  padding-bottom: 10px !important;
}

.bp-heading-new {
  font-size: 28px;
  font-weight: 500;

  @include theme.confirmo-media-breakpoint-down(sm) {
    text-align: center;
    font-size: 24px;
  }
}

.bp-subheading-new {
  font-size: 20px;
  font-weight: 500;
}

.bp-subheading-white {
  font-size: 16px;
  font-weight: 500;
  color: theme.$bp-color-background;
}

.bp-large-paragraph {
  font-size: 16px;
  font-weight: 500;
  width: max-content;
}

.bp-normal-paragraph-new {
  font-size: 15px;
  font-weight: 500;
  color: theme.$ashy-grey;
}

.bp-small-paragraph-new {
  font-size: 14px;
  font-weight: 500;
  color: theme.$ashy-grey;
}

.bp-very-small-paragraph-new {
  font-size: 13px;
  font-weight: 500;
  color: theme.$ashy-grey;
}

.bp-action-link {
  color: theme.$bp-color-grey;
  line-height: 32px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: theme.$bp-color-default;
  }
}

.platform-subtitle {
  font-size: 20px;
  font-weight: 500;
}

.confirmo-grey-text {
  font-size: 13px;
  font-weight: 500;
  color: theme.$currency-grey;
}

.confirmo-link {
  color: theme.$cf-color-brand-primary;
  font-weight: 500;
  text-decoration: underline;
  white-space: normal;
}

.confirmo-button-link {
  color: theme.$cf-color-brand-primary;
  font-weight: 500;
  text-decoration: underline;
  white-space: normal;
  border: none !important;
  background: none !important;
  padding: 0 !important;
}
