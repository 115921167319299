@use 'sass:map';

$_breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@mixin confirmo-media-breakpoint-up($name) {
  $size: map.get($_breakpoints, $name);

  @media (min-width: $size) {
    @content;
  }
}

@mixin confirmo-media-breakpoint-down($name) {
  $size: map.get($_breakpoints, $name);

  @media (max-width: $size - 0.02px) {
    @content;
  }
}

@mixin confirmo-media-breakpoint-between($name1, $name2) {
  $size1: map.get($_breakpoints, $name1);
  $size2: map.get($_breakpoints, $name2);

  @media (min-width: $size1) and (max-width: $size2 - 0.02px) {
    @content;
  }
}

@mixin bp-mobile-view {
  @media (max-width: 600px) {
    @content;
  }
}
