// Colors
$white: #ffffff;
$yellow: #ffd600;
$orange: #ffa60d;
$red: #ff1e0d;
$error-color: #ff7253;
$green: #00b34a;
$violet: #8100ff;
$gray: #f4f4f4;
$dimgray: #696969;
$darkgray: #5b5b5b;
$black: #000000;
$background-color: #ffffff4c;

// Font
$font-primary: 'IBM Plex Sans', sans-serif;
$font-size: 20px;
$line-height: calc(25 / 20);

// Typography
$typo-space-vertical: 1.25em; // ?

// Focus
$focus-outline-color: $yellow;
$focus-outline-style: solid;
$focus-outline-width: 2px;

// Spacing
$utils-spacing: (
  '0': 0,
  'xs': 15px,
  'sm': 30px,
  'md': 60px,
  'lg': 90px,
  'xl': 120px,
  'xxl': 150px,
);

// Grid
$grid-columns: 12;
$grid-gutter: 20px;

// Paths
$img-path: 'assets/images';
$fonts-path: 'assets/fonts';

// Transitions
$t: 0.3s;
