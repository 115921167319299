@use 'styles/landing/mixins';
@use 'styles/landing/variables';
@use 'styles/landing/animate';

.landing-container {
  /* stylelint-disable */ // (supercoders)
  * {
    --row-main-gutter: 20px;
    --row-main-width: calc(1680px + (2 * var(--row-main-gutter)));
    --scale: 0.5;
    font-size: 15px;
    line-height: calc(20 / 15);

    @include mixins.landing-media-breakpoint-up(lg) {
      --scale: 0.75;
      font-size: variables.$font-size;
      line-height: variables.$line-height;
    }

    @include mixins.landing-media-breakpoint-up(xxl) {
      --scale: 1;
    }

    &::before,
    &::after {
      box-sizing: inherit;
    }

    @include mixins.landing-media-breakpoint-up(xl) {
      --row-main-gutter: 60px;
    }

    strong,
    b {
      font-size: inherit;
      font-weight: 700;
    }
  }

  :first-child {
    margin-top: 0;
  }

  // Headings
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 1.5em 0 0.4em;
    font-family: variables.$font-primary;
    font-weight: 500;
    line-height: 1;
  }

  .h0 {
    margin-bottom: 0.9em;
    font-weight: 400;
    font-size: calc(var(--scale) * 120px);
    line-height: 1;

    @include mixins.landing-media-breakpoint-up(md) {
      margin-bottom: 0.55em;
    }
  }

  h1,
  .h1 {
    font-weight: 400;
    font-size: calc(var(--scale) * 80px);
    line-height: 1;
  }

  h2,
  .h2 {
    font-size: calc(var(--scale) * 40px);

    @include mixins.landing-media-breakpoint-down(lg) {
      line-height: calc(25 / 20);
    }
  }

  h3,
  .h3 {
    font-size: 20px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }

  h6,
  .h6 {
    font-size: 14px;
  }

  // Paragraph
  p {
    margin: 0 0 variables.$typo-space-vertical;
  }

  hr {
    height: 1px;
    margin: variables.$typo-space-vertical 0;
    border: solid variables.$black;
    border-width: 1px 0 0;
    overflow: hidden;
  }

  b {
    font-weight: 500;
  }

  // Blockquote
  blockquote {
    margin: 0 0 variables.$typo-space-vertical;
    padding: 0;

    p {
      margin-bottom: 0;
    }
  }

  // Links
  a,
  .as-link {
    color: variables.$violet;
    font-weight: 500;
    text-decoration: none;
    transition: color variables.$t !important;
    -webkit-tap-highlight-color: transparent;
    display: inline;
    padding: 0;
    border: 0;
    background: none;
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    appearance: none;
    cursor: pointer;

    .hoverevents &:hover {
      color: variables.$black;
    }
  }

  // Lists
  ul,
  ol,
  dl {
    margin: 0 0 variables.$typo-space-vertical;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 0.8em;
    padding: 0 0 0 20px;
  }

  ol {
    counter-reset: item;

    li {
      position: relative;

      &::before {
        content: counter(item) '.';
        counter-increment: item;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 500;
      }
    }

    ol {
      li {
        &::before {
          content: counter(item, lower-alpha) '.';
        }
      }
    }
  }

  dt {
    margin: 0;
    font-weight: 700;
  }

  dd {
    //margin: 0 0 calc(variables.$typo-space-vertical / 2);
    padding: 0;
  }

  caption {
    padding: 0 0 10px;
    font-weight: 700;
    text-align: left;
    caption-side: top;
  }

  // Image
  figure {
    margin-bottom: variables.$typo-space-vertical;
  }

  figcaption {
    margin-top: 0.5em;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  /* stylelint-enable */
}

.row-main {
  position: relative;
  width: 100%;
  max-width: var(--row-main-width);
  margin: 0 auto;
  padding: 0 var(--row-main-gutter);

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }

  .full-width {
    position: relative;
    left: 50%;
    width: calc(var(--vw, 1vw) * 100);
    margin-left: calc(var(--vw, 1vw) * -50);
  }
}

.landing-grid {
  counter-reset: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -20px -20px;

  &--center {
    justify-content: center;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--space-around {
    justify-content: space-around;
  }

  &--middle {
    align-items: center;
  }
}

.landing-grid__cell {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  border: 20px solid transparent;
  border-width: 0 0 20px 20px;
  border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E");
  margin: 0;
  padding: 0;
  background: none;

  &::before {
    content: normal;
    counter-increment: none;
    position: static;
    top: auto;
    left: auto;
  }
}

.landing-icon {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;

  @include mixins.landing-media-breakpoint-up(sm) {
    gap: 20px;
  }
}

.landing-icon__holder {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: variables.$white;
  box-shadow: 0 4px 15px rgb(0 0 0 / 0.08);

  @include mixins.landing-media-breakpoint-down(lg) {
    width: 50px;
    height: 50px;
  }
}

.landing-icon-svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 34px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.landing-icon-svg--angle {
  width: 10px;

  &::before {
    padding-top: 60%;
  }
}

.landing-icon-svg--arrow {
  width: 10px;

  &::before {
    padding-top: 160%;
  }
}

.landing-icon-svg--check-full {
  width: 20px;
}

.landing-item-icon {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
}

.landing-item-icon__icon {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  margin: 0 10px 0 0;
  transform: translateY(0.1em);
  transition: transform variables.$t;
}

.landing-box-timeline__item .landing-item-icon__icon {
  top: 0;
  width: 20px;
  height: 20px;

  @include mixins.landing-media-breakpoint-up(lg) {
    top: 2px;

    &.landing-supported-coins-bullet-points {
      top: 0;
    }
  }
}

.landing-icon-svg__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: translateZ(0);
}

.landing-icon__icon {
  width: 24px;
  height: 24px;
  color: variables.$black;

  @include mixins.landing-media-breakpoint-up(sm) {
    gap: 20px;
  }
}

.landing-icon--sm {
  .landing-icon__holder {
    width: 40px;
    height: 40px;

    @include mixins.landing-media-breakpoint-up(sm) {
      width: 50px;
      height: 50px;
    }
  }

  .landing-icon__icon {
    width: 20px;
    height: 20px;

    @include mixins.landing-media-breakpoint-up(sm) {
      width: 24px;
      height: 24px;
    }
  }
}

.landing-icon--md {
  .landing-icon__holder {
    @include mixins.landing-media-breakpoint-up(lg) {
      width: 80px;
      height: 80px;
    }
  }

  .landing-icon__icon {
    @include mixins.landing-media-breakpoint-up(lg) {
      width: 34px;
      height: 34px;
    }
  }
}

.landing-icon--lg {
  .landing-icon__holder {
    width: 80px;
    height: 80px;

    @include mixins.landing-media-breakpoint-up(lg) {
      width: 240px;
      height: 240px;
    }
  }

  .landing-icon__icon {
    width: 38px;
    height: 38px;

    @include mixins.landing-media-breakpoint-up(lg) {
      width: 120px;
      height: 120px;
    }
  }
}

.landing-util-mb-spec {
  margin-bottom: 135px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 200px;
  }
}

.landing-util-ml-spec {
  @include mixins.landing-media-breakpoint-up(md) {
    margin-left: calc(575 / 1720 * 100vw);
  }

  @include mixins.landing-media-breakpoint-up(xxl) {
    margin-left: 575px;
  }
}

.landing-util-mb-last-0 > *:last-child:not(.landing-grid) {
  margin-bottom: 0;
}

.landing-util-mb-sm.landing-util-mb-sm {
  margin-bottom: 30px;
}

// stylelint-disable-next-line
.landing-util-mb-md\@lg.landing-util-mb-md\@lg {
  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
}

.landing-util-mw-6-12 {
  width: 100%;
  max-width: calc((var(--row-main-width) - var(--row-main-gutter)) / 12 * 6 - var(--row-main-gutter));
}

.landing-util-mw-8-12 {
  width: 100%;

  @include mixins.landing-media-breakpoint-down(xxl) {
    max-width: calc((var(--row-main-width) - var(--row-main-gutter)) / 12 * 8 - var(--row-main-gutter));
  }
}

.landing-util-mw-10-12 {
  width: 100%;
  max-width: calc((var(--row-main-width) - var(--row-main-gutter)) / 12 * 10 - var(--row-main-gutter));
}

.landing-util-mx-auto {
  margin-right: auto;
  margin-left: auto;
}

// HOVERS start
.hoverevents .landing-menu-main__link:hover {
  color: variables.$violet;
}

.hoverevents .landing-menu-main__link.landing-item-icon:hover,
.hoverevents .landing-box-features__link:hover,
.hoverevents .landing-box-years__link:hover {
  color: variables.$black;
}

.hoverevents .landing-footer__link:hover {
  color: variables.$white;
}

.hoverevents .landing-box-references__btn:hover {
  opacity: 1;
  filter: grayscale(0);
}

.landing-menu-main__item.is-open,
.hoverevents .landing-menu-main__item:hover {
  .landing-item-icon__icon {
    @include mixins.landing-media-breakpoint-up(md) {
      transform: scale(-1);
    }
  }

  .landing-menu-submenu {
    visibility: visible;
    opacity: 1;
  }
}

.hoverevents .landing-crossroad-links__link:hover {
  @include mixins.landing-media-breakpoint-up(md) {
    .landing-crossroad-links__text {
      color: variables.$violet;
    }

    .landing-icon-svg__svg {
      background-color: variables.$violet;
    }
  }
}

.hoverevents .landing-tabs__link:hover {
  color: variables.$black;
}

.hoverevents .landing-crossroad-follow__link:hover .landing-crossroad-follow__arrow {
  transform: translate(10px, -10px) rotate(225deg);
}

.hoverevents .landing-box-std--cards .landing-box-std__item:hover .landing-box-std__inner {
  top: -5px;
  background: variables.$white;
  box-shadow: 0 64px 100px -24px rgba(variables.$black, 0.15);

  @include mixins.landing-media-breakpoint-up(md) {
    top: -20px;
  }
}

.hoverevents .coin-card-wrapper:hover .coin-card {
  background: variables.$white;
  box-shadow: 0 35px 35px -24px rgba(variables.$black, 0.15);
  cursor: pointer;

  @include mixins.landing-media-breakpoint-up(md) {
    top: -20px;
    box-shadow: 0 64px 100px -24px rgba(variables.$black, 0.15);
  }
}

.hoverevents .coin-card-wrapper:hover .coin-card-logo {
  box-shadow: 4px 4px 4px rgba(variables.$black, 0.05);

  @include mixins.landing-media-breakpoint-up(md) {
    box-shadow: 0 4px 12px rgba(variables.$black, 0.1);
  }
}

.hoverevents .coin-card-wrapper:hover .coin-card-logo-bw {
  opacity: 0.5;
  transition: all 0.1s ease-out;
}

.hoverevents .coin-card-wrapper:hover .coin-card-logo-color {
  opacity: 1;
  transition: all 0.1s ease-in;
}

.hoverevents .landing-menu-submenu__link:hover {
  background: rgb(244 244 244 / 0.5);
  color: variables.$violet;
}

.hoverevents .landing-header__link:hover {
  color: variables.$violet;
}

.hoverevents .landing-box-app-btns__btn:hover {
  box-shadow: 0 0 0 1px variables.$black;
}

.hoverevents .landing-btns__link:hover {
  color: variables.$violet !important;
}

/* stylelint-disable */ // (supercoders)
.hoverevents .landing-btns__link:hover span {
  transform: translateX(5px);
}

.hoverevents .landing-btn:hover .landing-btn__text {
  opacity: 0.8;
}

.hoverevents a:hover,
.hoverevents .as-link:hover {
  color: variables.$black;
}
/* stylelint-enable */

// HOVERS end

.is-menu-open .landing-header__holder {
  @include mixins.landing-media-breakpoint-down(lg) {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.is-menu-open .landing-box-burger__inner {
  .landing-box-burger-inner-layer {
    &:nth-child(1),
    &:nth-child(4) {
      top: 8px !important;
      right: 50% !important;
      width: 0 !important;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.landing-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  cursor: pointer;

  &:disabled,
  &.is-disabled {
    pointer-events: none;

    .landing-btn__text {
      background: rgb(0 0 0 / 0.2);
      color: variables.$white;
    }
  }
}

.landing-btn__text {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 9px 20px 11px;
  border: none;
  border-radius: 45px;
  background-color: variables.$black;
  color: variables.$white;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  transition:
    background-color variables.$t,
    color variables.$t,
    border-color variables.$t,
    opacity variables.$t;

  &.in-dialog {
    @include mixins.landing-media-breakpoint-down(xxl) {
      padding: 17px 20px 19px;
    }

    @include mixins.landing-media-breakpoint-down(lg) {
      padding: 15px 20px 17px;
    }

    @include mixins.landing-media-breakpoint-down(md) {
      padding: 13px 65px 15px;
    }
  }
}

.landing-btn--lg .landing-btn__text {
  @include mixins.landing-media-breakpoint-up(lg) {
    min-height: calc(var(--scale) * 80px);
    padding: calc(var(--scale) * 9px) calc(var(--scale) * 40px) calc(var(--scale) * 11px);
    border-radius: calc(var(--scale) * 40px);
    font-size: calc(var(--scale) * 20px);
  }
}

.landing-btn--lg:focus {
  outline: 0;
}

.landing-btns__link {
  color: variables.$black !important;
  font-size: 15px !important;

  @include mixins.landing-media-breakpoint-up(lg) {
    font-size: calc(var(--scale) * 20px) !important;
  }
}

.landing-btns__link__arrow {
  display: inline-block;
  transition: transform 0.3s;
  font-size: 15px;
  font-weight: 500;
}

.landing-box-head__title {
  max-width: 1200px;
  margin: 0 0 20px;

  @include mixins.landing-media-breakpoint-up(md) {
    margin-bottom: 30px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.landing-box-content__head {
  margin-bottom: 75px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 150px;
  }
}

.landing-box-head__content {
  @include mixins.landing-media-breakpoint-up(md) {
    max-width: 685px;
  }
}

.landing-box-features__head {
  margin: 0 0 75px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin: 0 0 165px;
  }
}

.landing-size--auto {
  width: auto;
}

.landing-size--4-12 {
  width: 33.33%;
}

.landing-size--6-12 {
  width: 50%;
}

// stylelint-disable-next-line
.landing-size--4-12\@md {
  @include mixins.landing-media-breakpoint-up(md) {
    width: 33.33%;
  }
}

.landing-util-print {
  @media not print {
    display: none;
  }
}

.landing-scrollable__content {
  overflow: scroll hidden;
  -ms-overflow-style: none;

  /* for Internet Explorer, Edge */
  scrollbar-width: none;

  /* for Firefox */
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;

    /* for Chrome, Safari, and Opera */
  }

  @include mixins.landing-media-breakpoint-down(xxl) {
    display: flex;
    margin-right: calc(var(--row-main-gutter) * -1);
    margin-left: calc(var(--row-main-gutter) * -1);
    padding: 0 var(--row-main-gutter);
    cursor: grab;

    &::after {
      content: '';
      display: block;
      flex: 0 0 auto;
      width: var(--row-main-gutter);
    }
  }
}

.landing-scrollable__inner {
  @include mixins.landing-media-breakpoint-down(xxl) {
    flex: 0 0 100%;
  }
}

.landing-img {
  position: relative;
  display: block;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
    pointer-events: none;
  }

  .landing-img__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
    }

    &.fill {
      object-fit: fill;
    }

    &.cover {
      object-fit: cover;
    }
  }

  &--contain .landing-img__img {
    object-fit: contain;
  }
}

.landing-box-list__head {
  margin-bottom: 70px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 145px;
  }
}

.landing-box-std__head {
  margin-bottom: 75px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 150px;
  }
}

.landing-box-numbers__head {
  margin-bottom: 55px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 130px;
  }
}

// stylelint-disable-next-line
a.landing-box-numbers__text__a {
  display: block;

  @include mixins.landing-media-breakpoint-down(md) {
    text-align: center;
  }
}

.landing-scrollable__dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: -5px;
}

.landing-scrollable__dot {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: variables.$white;

  &.is-active {
    background-color: variables.$violet;
  }
}

.landing-crossroad-links__title {
  margin: 0 0 40px !important;
  font-size: 12px !important;
  line-height: calc(15 / 12);

  @include mixins.landing-media-breakpoint-down(lg) {
    position: static;
    margin: 0 0 20px !important;
    font-size: 20px !important;
  }

  @include mixins.landing-media-breakpoint-up(lg) {
    font-size: 20px !important;
    line-height: calc(20 / 25);
  }
}

.landing-box-steps__head {
  margin-bottom: 75px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 150px;
  }
}

.landing-util-vhide {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.landing-box-call {
  text-align: center;
  margin-bottom: 80px;

  @include mixins.landing-media-breakpoint-up(lg) {
    margin-bottom: 165px;
  }
}

.landing-item-icon__text {
  flex: 1 1 auto;
  font-weight: 500;
  font-size: inherit;
}

.landing-box-intro__inner {
  grid-template-rows: 1fr auto;
  position: relative;

  @include mixins.landing-media-breakpoint-up(md) {
    display: grid;
    /* stylelint-disable */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(calc(70vh - 300px), 1fr);
    grid-template-areas: 'main img';
    /* stylelint-enable */
    gap: 80px 40px;
    align-items: flex-start;
    width: 100%;
  }
}

.landing-checkbox-input {
  appearance: none;
  margin: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  box-shadow: 0 3px 5px 0 rgb(0 29 99 / 0.03);
  border: solid 1.4px variables.$black;
  background-color: variables.$white;
  display: grid;
  place-content: center;
  overflow: hidden;

  &::before {
    content: '';
    width: inherit;
    height: inherit;
    cursor: pointer;
  }

  &:checked::before {
    background: variables.$black url('/assets/landing-new/check-white.svg') no-repeat center;
  }

  &:checked:disabled::before {
    background: variables.$gray url('/assets/landing-new/check-black.svg') no-repeat center;
    border-color: variables.$gray;
  }

  &:not(:checked):disabled::before {
    background: variables.$gray url('/assets/landing-new/check-black.svg') no-repeat center;
  }
}

.landing-switch-wrapper {
  position: relative;
  display: block;
}

.landing-switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 24px;

  @include mixins.landing-media-breakpoint-down(md) {
    width: 36px;
    height: 18px;
  }

  @include mixins.landing-media-breakpoint-down(sm) {
    width: 24px;
    height: 12px;
  }
}

.landing-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.landing-switch-text {
  color: #000000;
  font-size: 15px;
  padding-left: 10px;
  width: 100px;

  @include mixins.landing-media-breakpoint-down(md) {
    padding-left: 0;
  }

  @include mixins.landing-media-breakpoint-down(sm) {
    font-size: 12px;
    padding-left: 5px;
  }

  @include mixins.landing-media-breakpoint-down(xs) {
    font-size: 12px;
    padding-left: 5px;
    margin-top: -15px;
    width: 0;
  }
}

.landing-switch-slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #d9d9d9;
  transition: 0.4s;
  border-radius: 40px;
  width: inherit;

  &::before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 3.5px;
    background-color: #ffffff;
    transition: 0.4s;
    border-radius: 50%;

    @include mixins.landing-media-breakpoint-down(md) {
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2.5px;
    }

    @include mixins.landing-media-breakpoint-down(sm) {
      height: 8px;
      width: 8px;
      left: 2px;
      bottom: 2px;
    }
  }

  .landing-switch-input:checked + & {
    background-color: variables.$violet;
  }

  .landing-switch-input:checked + &::before {
    transform: translateX(24px);
    background-color: #ffffff;

    @include mixins.landing-media-breakpoint-down(md) {
      transform: translateX(19px);
    }

    @include mixins.landing-media-breakpoint-down(sm) {
      transform: translateX(12px);
    }
  }

  .landing-switch-input:disabled + & {
    opacity: 0.6;
    cursor: initial;
  }
}

html:not([data-scroll='0']) .landing-header {
  background: rgb(244 244 244 / 0.7);
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  transition: background-color 0.3s ease-in;

  @include mixins.landing-media-breakpoint-down(md) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
