@use 'styles/landing/mixins';

.js *[data-animate-target='element'] {
  @media not print {
    $d: 0.8s;
    $offset: 30px;
    will-change: transform, opacity, width;

    // STATES
    &:not([data-direction='static']) {
      position: relative;
      transition:
        transform $d,
        opacity $d linear;

      .landing-icon {
        transition: transform $d;
      }
    }

    &:not([data-direction='static'], .is-animated) {
      opacity: 0;
      transform: translateY($offset);

      .landing-icon {
        transform: scale(0) translateY(-$offset);
      }
    }

    // MODIF
    &[data-direction='skew']:not(.is-animated) {
      transform: translateY(50px) skewY(8deg);
    }

    &[data-direction='bottom']:not(.is-animated) {
      transform: translateY(-$offset);
    }

    &[data-direction='left']:not(.is-animated) {
      transform: translateX($offset);
    }

    &[data-direction='right']:not(.is-animated) {
      transform: translateX(-$offset);
    }

    &[data-direction='static']:not(.is-animated) {
      transform: none;
    }

    // MQ
    @include mixins.landing-media-breakpoint-up(lg) {
      &[data-direction='skew']:not(.is-animated) {
        transform: translateY(150px) skewY(10deg);
      }
    }

    @include mixins.landing-media-breakpoint-up(xl) {
      &[data-direction='skew']:not(.is-animated) {
        transform: translateY(300px) skewY(17deg);
      }
    }
  }
}
