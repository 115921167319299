@mixin bp-box-shadow-no-border($color) {
  box-shadow:
    0 1px 1px rgb(0 0 0 / 0.08) inset,
    0 0 8px $color !important;
}

@mixin bp-box-shadow($color) {
  border: 1px solid $color !important;

  @include bp-box-shadow-no-border($color);
}

@mixin cf-platform-input-select-focus($color) {
  border: 1px solid $color !important;
}

@mixin bp-elevated {
  background: #ffffff;
  box-shadow: 0 0 5px #bbbbbb;
}

@mixin bp-card-shadow {
  box-shadow:
    0 20px 55px 0 rgb(0 29 99 / 0.09),
    0 8px 9px 0 rgb(0 29 99 / 0.03);
}

@mixin bp-elevated-new {
  background: #ffffff;

  @include bp-card-shadow;
}

@mixin bp-button-reset {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: inherit;
}

@mixin style-thin-scrollbar($width, $thumb-color, $track-color) {
  scrollbar-width: thin; // stylelint-disable-line

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: 35px;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: 35px;
  }
}

@mixin style-thin-scrollbar-with-border($width, $thumb-color, $border-color, $track-color) {
  scrollbar-width: thin; // stylelint-disable-line

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: 35px;
    border: 6px solid $border-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }
}

@mixin bp-table-scrollable-down {
  @media (max-width: 799.98px) {
    @content;
  }
}

@mixin bp-table-scrollable-up {
  @media (min-width: 800px) {
    @content;
  }
}
