@forward 'styles/grid';
@forward 'styles/deep-resets';
@forward 'styles/mixins';
@forward 'styles/platform/platform-breakpoints-mixins';
@forward 'styles/platform/platform-variables';
@forward 'styles/index-loading';
@use 'styles/platform/platform-variables'; // TODO: remove this cyclic style import

.dialog-backdrop-purple {
  background: rgb(32 22 91 / 0.7);
  transition-duration: 0.4s;
}

.dialog-backdrop-grey {
  background: rgba(0 0 0 / 0.5);
  transition-duration: 0.4s;
}

.image-overlay-service-qr-code-backdrop {
  background-color: rgb(255 255 255 / 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// TODO: move these to remove the cyclic style import above
/* stylelint-disable */ //to be able to use uppercase
.currency-highlighted {
  &.BTC {
    background-color: platform-variables.$btc;
  }

  &.LTC {
    background-color: platform-variables.$ltc;
  }

  &.ETH {
    background-color: platform-variables.$eth;
  }

  &.TRX {
    background-color: platform-variables.$trx;
  }

  &.SOL {
    background-color: platform-variables.$sol;
  }

  &.USDT {
    background-color: platform-variables.$usdt;
  }

  &.USDC {
    background-color: platform-variables.$usdc;
  }

  &.MATIC {
    background-color: platform-variables.$matic;
  }
}
/* stylelint-enable */
