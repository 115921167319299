/* Borders */
$cf-border-radius-s: 2px;
$cf-border-radius-m: 4px;
$cf-border-radius-l: 8px;
$cf-border-width-s: 1px;
$cf-border-width-m: 2px;
$cf-border-width-l: 4px;

/* Buttons - TODO CBP-5447 use padding sizes from "Spacing" section */
$cf-button-padding-inline-m: 24px;
$cf-button-padding-block-m: 12px;
$cf-link-button-padding-inline-m: 14px;
$cf-link-button-padding-block-m: 22px;

/* Colors */
$cf-color-brand-primary: #8100ff;
$cf-color-brand-primary-light: #f8f3ff;
$cf-color-brand-primary-focus: #a46aff;
$cf-color-brand-secondary: #ffd600;
$cf-color-brand-secondary-focus: #ffea80;
$cf-color-error: #f44336;
$cf-color-black: #000000;
$cf-color-text-dark: #000000; // TODO CBP-5447 $cf-color-gray-500? --naming is the hardest part of programming
$cf-color-text-gray: #767d84; // TODO CBP-5447 $cf-color-gray-400?
$cf-color-gray-300: #d0d4e1;
$cf-color-gray-200: #e7ebf8;
$cf-color-gray-100: #f4f4f4;
$cf-color-white: #ffffff;

/* Fonts */
// TODO CBP-5447 font sizes -- I'd rather use rem units for better responsiveness
$cf-font-size-s: 12px;
$cf-font-size-m: 16px;
$cf-font-size-l: 20px;
$cf-font-size-xl: 24px;
$cf-font-size-xxl: 32px;

// TODO CBP-5447 refine
$cf-line-height-s: 1.2;
$cf-line-height-m: 1.5;
$cf-line-height-l: 1.8;

// TODO CBP-5447 (based on IBM Plex Sans) commented out are not imported, should not be used in design
$cf-font-weight-regular: 400;
$cf-font-weight-medium: 500;
$cf-font-weight-semi-bold: 600;

/* Shadows */ // TODO refine (just added examples)
// $cf-shadow-s: 0px 1px 3px rgba(0, 0, 0, 0.1);
// $cf-shadow-m: 0px 4px 6px rgba(0, 0, 0, 0.1);
// $cf-shadow-l: 0px 10px 15px rgba(0, 0, 0, 0.2);

/* Spacing TODO CBP-5447 the spaces in the design seem to be a bit random, discuss */
$cf-spacing-xs: 4px;
$cf-spacing-s: 8px;
$cf-spacing-m: 16px;
$cf-spacing-l: 24px;
$cf-spacing-xl: 32px;
$cf-spacing-xxl: 64px;

/* Transitions */ // TODO CBP-5447 refine
$cf-transition-ease: cubic-bezier(0.4, 0, 0.2, 1);
$cf-transition-fast: 100ms;
$cf-transition-medium: 200ms;
$cf-transition-slow: 300ms;

/* Legacy colors - most still in use */
$bp-color-primary: #3a2f46;
$bp-color-background: #ffffff;
$bp-color-default: #333333;
$bp-color-grey: #888888;
$bp-color-lightgrey: #cccccc;
$bp-color-success: #4caf50;
$bp-color-warning: #f44336;
$bp-color-info: $cf-color-brand-primary; // TODO CBP-5447 rename var
$bp-color-light: #e6e6e6;
$bp-color-soft-blue: $cf-color-brand-primary; // TODO CBP-5447 rename var
$bp-color-dark: #3a3653;
$bp-color-bluey-grey: #979aa1;
$bp-color-battleship-grey: #6b6e78;
$bp-color-cool-grey: #979caa;
$pale-yellow: #faf8f3;
$off-yellow: #ffdd3c;
$yellow: #e9ae00;
$watermelon: #ff415b;
$watermelon-faded: #ff6377;
$pink: #f3c0be;
$light-pink: #ffecef;
$light-green: #e4fbea;
$active-green: #249742;
$light-blue: $cf-color-brand-primary-light; // TODO CBP-5447 rename var
$info-blue: $cf-color-brand-primary-light; // TODO CBP-5447 rename var
$focus-blue: $cf-color-brand-primary-focus; // TODO CBP-5447 rename var
$dark-blue: $cf-color-brand-primary; // TODO CBP-5447 rename var
$link-blue: $cf-color-brand-primary; // TODO CBP-5447 rename var
$black: #0d1c2b;
$mono-grey: #667382;
$battleship-grey: #6b6e78;
$ashy-grey: #767d84;
$expired-grey: #999999;
$cool-grey: #979caa;
$currency-grey: #96a4b4;
$transparent-grey: #d0d4e1;
$disabled-grey: #e9ecef;
$border-grey: #e7ebf8;
$dark-grey: #d4d7e3;
$light-grey: #f4f4f4;
$scrollbar-grey: #8c8c8c;
$background-grey: #f7f7f9;
$box-shadow: 0 10px 15px -2px #d9d9d9;
$btc: #ffd1ad;
$ltc: #f0efeb;
$eth: #dfe7fd;
$trx: #f391b5;
$sol: #bee1e6;
$usdt: #86e0a4;
$usdc: #87afe0;
$matic: #d5bbff;
