@use 'styles/theme';
@use 'ngx-toastr/toastr';

.toast-container .ngx-toastr {
  box-shadow: 0 2px 15px 0 rgb(195 201 219 / 0.65);
  border-radius: 4px;
}

.toast-close-button:hover {
  color: #ffffff;
}

.toast-error {
  background-color: theme.$watermelon;

  &.ngx-toastr:hover {
    @include theme.bp-box-shadow-no-border(theme.$watermelon);
  }
}

.toast-info {
  background-color: theme.$link-blue;

  &.ngx-toastr:hover {
    @include theme.bp-box-shadow-no-border(theme.$link-blue);
  }
}

.toast-success {
  background-color: theme.$active-green;

  &.ngx-toastr:hover {
    @include theme.bp-box-shadow-no-border(theme.$active-green);
  }
}

.toast-warning {
  background-color: theme.$yellow;

  &.ngx-toastr:hover {
    @include theme.bp-box-shadow-no-border(theme.$yellow);
  }
}
