@use 'styles/platform/platform-breakpoints-mixins';

.confirmo-row {
  display: flex;
  flex-wrap: wrap;

  .col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .col-xs {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .col-sm {
    &__small {
      padding-left: 15px;
      padding-right: 15px;

      @include platform-breakpoints-mixins.confirmo-media-breakpoint-up(sm) {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }

    &__medium {
      padding-left: 15px;
      padding-right: 15px;

      @include platform-breakpoints-mixins.confirmo-media-breakpoint-up(sm) {
        flex: 0 0 50%;
        width: 50%;
      }
    }

    &__large {
      padding-left: 15px;
      padding-right: 15px;

      @include platform-breakpoints-mixins.confirmo-media-breakpoint-up(sm) {
        flex: 0 0 70%;
        max-width: 70%;
      }
    }
  }
}
