.index-loading-background {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  position: fixed;
}

.index-loading-background-bubble {
  width: 854px;
  height: 854px;
  border-radius: 50%;
  filter: blur(50px);
  position: absolute;
  z-index: -1;

  &.pink {
    background: radial-gradient(closest-side, #8100ff 50%, rgba(129 0 255 / 0));
    left: 15%;
    top: -285px;
  }

  &.yellow {
    background: radial-gradient(closest-side, #ffd600 50%, rgba(255 214 0 / 0));
    right: 10%;
    top: 190px;
  }
}

.index-loading-text {
  text-align: center;
  font-size: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}
