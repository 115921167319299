@use 'styles/theme';

$table-head-background-color: #ffffff;
$table-row-background-color-hover: theme.$light-grey;

.bp-table {
  .bp-table-head {
    background-color: $table-head-background-color;
    z-index: 1 !important;

    &.bp-table-header-fixed {
      position: fixed;
    }
  }

  .bp-table-empty {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    margin-top: 20px;
  }

  .bp-table-row {
    display: flex;
    border-top: 1px solid rgb(0 0 0 / 0.12);

    .bp-action-button {
      min-width: 0 !important;
      width: 36px !important;
      padding: 0 !important;
      margin-right: 10px !important;
      visibility: hidden;
      pointer-events: none;
    }

    .bp-action-button-sizeable {
      margin-right: 10px !important;
      visibility: hidden;
      pointer-events: none;
    }

    &:hover .bp-action-button,
    &:hover .bp-action-button-sizeable {
      visibility: visible;
      pointer-events: auto;
    }
  }

  .bp-table-head-row {
    display: flex;
  }

  .bp-table-head-row-additions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  .bp-table-body {
    .bp-table-row:hover {
      background-color: $table-row-background-color-hover;
    }
  }

  .bp-table-cell {
    font-size: 14px;
    padding: 14px 20px;
    flex-basis: 100%;
    flex-shrink: 1;
    overflow: hidden;
    word-break: break-all;

    &.bp-table-cell-checkbox {
      flex-basis: 20%;
      padding: 14px 24px;
    }

    &.bp-table-cell-status {
      text-transform: uppercase;
    }
  }

  .bp-table-head-cell {
    flex-basis: 100%;
    flex-shrink: 1;
    text-align: left;
    padding: 14px 24px;
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.bp-table-head-checkbox {
      flex-basis: 20%;
    }

    .bp-table-head-cell-title {
      display: flex;
      align-items: center;
      color: theme.$bp-color-primary;
      font-weight: 700;
      font-size: 12px;
      height: 24px;

      &.bp-table-head-cell-title-sortable {
        cursor: pointer;
      }

      .bp-table-empty-material-icons {
        display: block;
        width: 24px;
      }
    }

    .bp-table-head-cell-title-right {
      display: flex;
      align-items: center;
      color: theme.$bp-color-primary;
      font-weight: 700;
      font-size: 12px;
      height: 24px;
      justify-content: flex-end;
    }
  }

  .bp-search-button {
    position: relative;
    top: -3px;
    min-width: 0;
    margin-left: 10px;
  }
}

.bp-table-new {
  position: relative;
  box-sizing: border-box;
  width: max-content;
  max-width: calc(100vw - 400px);
  min-width: 100%;

  &__auto-width {
    width: auto !important;
  }
}

.bp-table-head-new {
  background-color: $table-head-background-color;
  z-index: 1 !important;
  font-size: 14px;
  font-weight: 700;
  color: theme.$black;
}

.bp-table-empty-new {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 20px;
  color: theme.$currency-grey;
  height: 100px;
  margin-top: 50px;
}

.bp-table-content-row-new {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 79px;
  margin: 0 60px 0 59px;
  border-bottom: solid 1px theme.$border-grey;

  @include theme.bp-table-scrollable-down {
    margin: 0 22px;
    height: 69px;
  }
}

.bp-table-row-new {
  display: flex;

  &:hover {
    @include theme.confirmo-media-breakpoint-up(sm) {
      background-color: $table-row-background-color-hover;
    }
  }

  &:only-child {
    min-height: 150px;
  }

  &:last-child .bp-table-content-row-new {
    border-bottom: none;
    margin-bottom: 29px;

    @include theme.bp-table-scrollable-down {
      margin-bottom: 0;
    }
  }
}

.bp-table-head-row-content-new {
  width: 100%;
  display: flex;
  align-items: center;
  height: 101px;
  margin: 0 60px 0 59px;

  @include theme.bp-table-scrollable-down {
    margin: 0 22px;
    height: 79px;
  }
}

.bp-table-head-row-new {
  display: flex;
  align-items: center;
  border-bottom: solid 1px theme.$border-grey;
}

.bp-table-body-new {
  font-size: 13px;
  font-weight: 500;
}

.bp-table-cell-new {
  font-size: 14px;
  word-break: break-all;
  display: flex;
  align-items: center;
  white-space: pre;
  position: relative;

  &__left {
    justify-content: flex-start;
  }

  &__center {
    justify-content: center;
    text-align: center;
  }

  &__right {
    justify-content: flex-end;
  }

  &__flex-content {
    justify-content: center;
  }
}

.bp-table-cell-overflowed-content {
  width: 100%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bp-container-scrollable {
  transform: scaleY(-1);
  overflow: visible;
  min-width: 800px;

  &.no-static-col {
    min-width: auto;
  }
}

.annul-right-padding {
  padding-right: 0 !important;
}

.annul-left-padding {
  padding-left: 0 !important;
}

.bp-fixed-col {
  display: none;

  @include theme.bp-table-scrollable-down {
    display: block;
    position: absolute;
    width: max-content;
    z-index: 2;
    box-shadow: 2px 0 10px 0 rgb(0 29 99 / 0.1);
    border: solid 1px theme.$border-grey;
    border-bottom: none;
    border-top: none;
    background-color: #ffffff;
  }
}

.bp-hidden-on-mobile {
  display: flex;

  @include theme.bp-table-scrollable-down {
    display: none;
  }
}

.bp-shifted-on-mobile {
  &__short-shift {
    @include theme.bp-table-scrollable-down {
      margin-left: 105px;
    }
  }

  &__medium-shift {
    @include theme.bp-table-scrollable-down {
      margin-left: 125px;
    }
  }

  &__payment-buttons {
    @include theme.bp-table-scrollable-down {
      margin-left: 145px;
    }
  }

  &__payouts {
    @include theme.bp-table-scrollable-down {
      margin-left: 140px;
    }
  }

  &__invoices {
    @include theme.bp-table-scrollable-down {
      margin-left: 170px;
    }
  }

  &__settlements {
    @include theme.bp-table-scrollable-down {
      margin-left: 135px;
    }
  }

  &__email-invoices {
    @include theme.bp-table-scrollable-down {
      margin-left: 153px;
    }
  }

  &__users {
    @include theme.bp-table-scrollable-down {
      margin-left: 157px;
    }
  }

  &__api-keys {
    @include theme.bp-table-scrollable-down {
      margin-left: 150px;
    }
  }

  &__supported-currencies {
    @include theme.bp-table-scrollable-down {
      margin-left: 190px;
    }
  }

  &__pos-devices {
    @include theme.bp-table-scrollable-down {
      margin-left: 140px;
    }
  }
}

.bp-static-content-row {
  @include theme.bp-table-scrollable-down {
    margin-left: 15px;
    margin-right: 0;
  }
}

.bp-static-head-row {
  @include theme.bp-table-scrollable-down {
    padding-left: 15px;
    padding-right: 16px;
    height: 80px;
    border-bottom: solid 1px theme.$border-grey;
  }
}

.bp-table-empty-col {
  @include theme.bp-table-scrollable-down {
    width: 100%;
    min-height: 150px;
  }
}
