@use 'styles/theme';

.centered-content {
  display: flex;
  justify-content: center;
  min-width: min-content;
}

.bp-scrollable-content-rotated {
  transform: scaleY(-1);
  overflow-x: auto;

  @include theme.style-thin-scrollbar-with-border(5px, theme.$dark-grey, theme.$light-grey, theme.$light-grey);

  /*
  this is the difference from the design:
  it hides scrollbar on mobile devices, because it behaved inconsistently in different browsers and devices
  */
  @include theme.bp-table-scrollable-down {
    scrollbar-width: none; // stylelint-disable-line

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

@mixin list-content {
  box-sizing: content-box;
  padding: 41px 0 80px;
  width: 100vw;

  @include theme.confirmo-media-breakpoint-up(xl) {
    width: 100%;
    padding: 46px 50px 80px;
    max-width: 1410px;
  }
}

@mixin admin-list-content {
  padding: 30px 50px 80px;
  width: 100%;

  @include theme.confirmo-media-breakpoint-down(md) {
    width: 100vw;
    padding: 30px 0 80px;
  }
}

@mixin list-header-flex() {
  align-items: center;
  padding: 0 35px;

  @include theme.confirmo-media-breakpoint-down(sm) {
    height: auto;
  }

  @include theme.confirmo-media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 29px;
    padding: 0;
  }

  @include theme.confirmo-media-breakpoint-between(sm, xl) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.list-header {
  height: 44px;
  display: block;
  margin-bottom: 26px;

  @include list-header-flex;
}

.list-header-with-checkbox {
  display: block;
  margin-bottom: 29px;
  margin-top: 26px;

  @include list-header-flex;
}

.list-search-container {
  padding: 26px 0 29px;

  @include theme.confirmo-media-breakpoint-up(sm) {
    border-top: none;
    padding-top: 0;
    width: 100%;
  }

  @include theme.confirmo-media-breakpoint-down(md) {
    padding-left: 35px;
    padding-right: 35px;
    width: 100%;
  }

  @include theme.confirmo-media-breakpoint-between(sm, xl) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  @include theme.confirmo-media-breakpoint-up(lg) {
    max-width: 750px;
  }
}

.list-search {
  max-width: 750px;
  width: 100%;

  @include theme.confirmo-media-breakpoint-down(sm) {
    border-top: none;
    padding-top: 0;
    width: 100%;
  }
}

.list-search-filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 830px;
}

.list-divider {
  display: none;

  @include theme.confirmo-media-breakpoint-down(sm) {
    display: block;
    height: 1px;
    background-color: theme.$border-grey;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.filter-button {
  width: 50px !important;
  margin-left: 15px;
  position: relative;
}

.filter-button-red-indicator {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: theme.$watermelon;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  // stylelint-disable-next-line
  box-shadow:
    0 1px 1px theme.$watermelon inset,
    0 0 8px theme.$watermelon !important;
}

.filter-icon {
  margin-top: 2px;
}
