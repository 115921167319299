@keyframes appear-up {
  0% {
    transform: translate3d(0, 20px, 0);
  }

  100% {
    transform: translateZ(0);
  }
}

@keyframes appear-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes disappear-down {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}

.animate {
  &__appear-up {
    animation-name: appear-up;
    animation-duration: 0.8s;
    animation-delay: 0s;
    animation-fill-mode: backwards;
  }

  &__appear-down {
    animation-name: appear-down;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;

    &__fill-mode-both {
      animation-name: appear-down;
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      animation-fill-mode: both;
    }
  }

  &__disappear-down {
    animation-name: disappear-down;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
}
