@use 'sass:map';
@use 'styles/landing/variables';

$_breakpoints: (
  xs: 375px,
  sm: 480px,
  md: 750px,
  lg: 1000px,
  xl: 1200px,
  xxl: 1800px,
);

@mixin landing-media-breakpoint-up($name) {
  $size: map.get($_breakpoints, $name);

  @media (min-width: $size) {
    @content;
  }
}

@mixin landing-media-breakpoint-down($name) {
  $size: map.get($_breakpoints, $name);

  @media (max-width: $size - 0.02px) {
    @content;
  }
}

@mixin landing-media-breakpoint-between($name1, $name2) {
  $size1: map.get($_breakpoints, $name1);
  $size2: map.get($_breakpoints, $name2);

  @media (min-width: $size1) and (max-width: $size2 - 0.02px) {
    @content;
  }
}

// Clearfix
@mixin clearfix() {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

// Hiding content
@mixin vhide() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@mixin button-reset {
  display: inline-block;
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  line-height: inherit;
  text-align: inherit;
  cursor: pointer;
  appearance: none;
}
