@use 'styles/theme';

$bp-badge-color-unfinished: #000000;
$bp-badge-color-semifinished: #6699cc;
$bp-badge-color-finished: #009966;
$bp-badge-color-invalid: #999999;
$bp-badge-color-deferred: #cc9900;
$bp-badge-color-erroneous: #cc0000;
$bp-badge-color-dark-red: #871f15;
$bp-badge-color-prepared: #ffd700;
$bp-badge-color-ghost: #6b6e78;
$bp-badge-color-new: theme.$cf-color-brand-primary;
$bp-badge-color-regular: #4caf50;
$bp-badge-color-suspended: #f44336;
$bp-badge-color-testing: #1a2c3f;

.bp-badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;

  &.bp-badge-invoice__active {
    background-color: $bp-badge-color-unfinished;
  }

  &.bp-badge-invoice__prepared {
    background-color: $bp-badge-color-prepared;
  }

  &.bp-badge-invoice__confirming {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-invoice__paid {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-invoice__expired {
    background-color: $bp-badge-color-invalid;
  }

  &.bp-badge-invoice__error {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-settlement__sending {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-settlement__sent {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-settlement__on-hold {
    background-color: $bp-badge-color-deferred;
  }

  &.bp-badge-settlement__failed {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-settlement__blocked-over-limit {
    background-color: $bp-badge-color-dark-red;
  }

  &.bp-badge-refund__overpaid {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-refund__underpaid {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-refund__pending {
    background-color: $bp-badge-color-deferred;
  }

  &.bp-badge-refund__error {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-refund__sending {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-refund__paid-after-limit {
    background-color: $bp-badge-color-invalid;
  }

  &.bp-badge-market-operation__new {
    background-color: $bp-badge-color-unfinished;
  }

  &.bp-badge-market-operation__sent {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-market-operation__open {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-market-operation__cancelled {
    background-color: $bp-badge-color-deferred;
  }

  &.bp-badge-market-operation__sold {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-market-operation__error {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-market-operation__error-market {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-settlement-method__active {
    background-color: $bp-badge-color-unfinished;
  }

  &.bp-badge-settlement-method__disabled {
    background-color: $bp-badge-color-invalid;
  }

  &.bp-badge-profile {
    background-color: $bp-badge-color-unfinished;
  }

  &.bp-badge-profile-flag {
    background-color: $bp-badge-color-dark-red;
  }

  &.bp-badge-email-invoice__active {
    background-color: $bp-badge-color-unfinished;
  }

  &.bp-badge-email-invoice__paid {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-email-invoice__expired {
    background-color: $bp-badge-color-erroneous;
  }

  &.bp-badge-other-side-transactions__prepared {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-other-side-transactions__confirmed {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-other-side-transactions__sending {
    background-color: $bp-badge-color-semifinished;
  }

  &.bp-badge-other-side-transactions__done {
    background-color: $bp-badge-color-finished;
  }

  &.bp-badge-other-side-transactions__expired {
    background-color: $bp-badge-color-deferred;
  }

  &.bp-badge-other-side-transactions__canceled {
    background-color: $bp-badge-color-unfinished;
  }

  /* stylelint-disable */ //to be able to use uppercase
  &.NEW {
    background-color: $bp-badge-color-new;
  }

  &.REGULAR {
    background-color: $bp-badge-color-regular;
  }

  &.TESTING {
    background-color: $bp-badge-color-testing;
  }

  &.SUSPENDED {
    background-color: $bp-badge-color-suspended;
  }

  &.GHOST {
    background-color: $bp-badge-color-ghost;
  }
  /* stylelint-enable */
}
